.react-calendar span,
.react-calendar abbr {
  font-family: 'Outfit-Regular';
}

.react-calendar {
  border: none;
}

abbr[title] {
  text-decoration: none !important;
}

.react-calendar__navigation__label span {
  font-size: 18px;
  font-family: 'Outfit-SemiBold';
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: none;
  color: #b6c1cd;
  font-size: 14px;
}

.react-calendar__month-view__days__day--weekend {
  color: inherit
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #d9e1e8
}

.react-calendar__tile {
  width: 50px;
  height: 50px;
  font-size: 16px;
  border-radius: 50%;
}
:not(.react-calendar__tile--rangeStart,.react-calendar__tile--rangeEnd).react-calendar__tile--range,
:not(.react-calendar__tile--hoverStart,.react-calendar__tile--hoverEnd).react-calendar__tile--hover {
  border-radius: 0;
}
:not(.react-calendar__tile--rangeEnd).react-calendar__tile--rangeStart,
:not(.react-calendar__tile--hoverEnd).react-calendar__tile--hoverStart {
  border-radius: 50% 0 0 50%;
}
:not(.react-calendar__tile--rangeStart).react-calendar__tile--rangeEnd,
:not(.react-calendar__tile--hoverStart).react-calendar__tile--hoverEnd {
  border-radius: 0 50% 50% 0;
}
.react-calendar__tile--now {
  background:inherit;
  color: #81f8ff;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--hasActive:enabled:hover {
  background-color: #e6e6e6 !important;
}


.react-calendar__tile--active,
.react-calendar__tile--hasActive
 {
  background: #81f8ff !important;
  color: #1b3e3a;
}

.react-calendar__tile:disabled {
  background-color: inherit;
  color: #E6E6E6
}

.react-calendar__navigation button[disabled] {
  background-color: inherit;
  opacity: 0.3;
}